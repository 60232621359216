<template>
    <div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import * as Sentry from '@sentry/vue'

    export default {
        computed: {
            userLoggedIn() {
                return this.$store.getters['user/loggedIn']
            }
        },
        async mounted() {
            if (!this.userLoggedIn) {
                this.$router.push({name: 'login'})
            } else {
                try {
                    const clientId = this.$route.params.client_id
                    const response = await this.$http.get(`/api/client/v1/change_client/${clientId}`)
                    await this.$store.dispatch('user/setUserData', response.data)

                    const res = await Vue.prototype.$http.get('/api/client/v1/users/permissions')
                    const permissions = res.data ?? []

                    const res1 = await Vue.prototype.$http.get('/api/client/v1/client/data')
                    const clientData = res1.data ?? {}

                    this.$store.dispatch('user/setUserPermissions', permissions)
                    this.$store.dispatch('client/setClientData', clientData)
                    await this.$store.dispatch('setup/setWoltIntegration')

                } catch (err) {
                    Sentry.captureException(err)
                } finally {
                    await this.$router.push({name: 'home'})
                }
            }


        }
    }
</script>